<template>
  <div class="table-page-search-wrapper">
    <a-form layout="inline">
      <a-row :gutter="48">
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.homeTemplateName')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-input
              v-model="queryParam.homeTemplateName"
              v-decorator="[
                'queryParam.homeTemplateName',
                {
                  rules: [
                    { required: true, message: '请输入' + this.$t('field.homeTemplateName') },
                    { max: 100, message: this.$t('field.homeTemplateName') + '长度小于等于100' }
                  ]
                }
              ]"
              :placeholder="'请输入' + this.$t('field.homeTemplateName')"
              name="queryParam.homeTemplateName"
            />
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            :label="$t('field.marketplaceCode')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select :allowClear="true" v-model="queryParam.marketplaceCode" mode="default">
              <a-select-option
                v-for="(item, index) in codeList.marketplaceCode"
                :key="index"
                :value="item.marketplaceCode"
                >{{ item.marketplaceCode }}-{{ item.marketplaceName }}
              </a-select-option>
            </a-select></a-form-item
          >
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.siteCode')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select :allowClear="true" v-model="queryParam.siteCode" mode="multiple">
              <a-select-option v-for="(item, index) in codeList.siteCode" :key="index" :value="item.siteCode"
                >{{ item.siteCode }}-{{ item.siteName }}
              </a-select-option>
            </a-select></a-form-item
          >
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.language')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select :allowClear="true" v-model="queryParam.language" mode="default">
              <a-select-option v-for="(item, index) in codeList.language" :key="index" :value="item.value"
                >{{ item.label }}
              </a-select-option>
            </a-select></a-form-item
          >
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item
            v-if="showAdvancedSearchCondition"
            :label="$t('field.onlineStatus')"
            :labelCol="{ lg: { span: 7 }, sm: { span: 7 } }"
            :wrapperCol="{ lg: { span: 10 }, sm: { span: 17 } }"
          >
            <a-select :allowClear="true" v-model="queryParam.onlineStatus" mode="default">
              <a-select-option v-for="(item, index) in codeList.onlineStatus" :key="index" :value="item.value"
                >{{ item.label }}
              </a-select-option>
            </a-select></a-form-item
          >
        </a-col>

        <!--        <template v-if="showAdvancedSearchCondition">-->
        <!--          <a-col :md="8" :sm="24">-->
        <!--            <a-form-item label="更新日期">-->
        <!--              <a-date-picker v-model="queryParam.date" placeholder="请输入更新日期" style="width: 100%"/>-->
        <!--            </a-form-item>-->
        <!--          </a-col>-->
        <!--        </template>-->
        <a-col :md="24" :sm="24">
          <span
            :style="(showAdvancedSearchCondition && { overflow: 'hidden' }) || {}"
            class="table-page-search-submitButtons"
          >
            <a-button type="primary" @click="$parent.$parent.$refs.table.refresh(true)">{{
              $t('button.search')
            }}</a-button>
            <a-button
              style="margin-left: 8px"
              @click="
                () => {
                  $parent.$parent.queryParam = {}
                  $nextTick(() => {
                    $parent.$parent.$refs.table.refresh(true)
                  })
                }
              "
              >{{ $t('button.reset') }}</a-button
            >
            <a style="margin-left: 8px" @click="toggleAdvancedSearch">
              {{ showAdvancedSearchCondition ? '收起' : '展开' }}
              <a-icon :type="showAdvancedSearchCondition ? 'up' : 'down'" />
            </a>
          </span>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import moment from 'moment'
import { listMarketplace } from '@/api/system/marketplace'

export default {
  name: 'HomeTemplateSearch',
  components: {},
  props: {
    // 查询参数
    queryParam: {
      type: Object
    }
  },
  data() {
    return {
      showAdvancedSearchCondition: false,
      codeList: {
        marketplaceCode: [],
        siteCode: [],
        onlineStatus: [
          { label: '未上线', value: '未上线' },
          { label: '上线中', value: '上线中' },
          { label: '已下线', value: '已下线' }
        ]
      }
    }
  },
  created() {},
  mounted() {
    listMarketplace({}).then(res => {
      this.codeList.marketplaceCode = res.data
    })
  },
  methods: {
    toggleAdvancedSearch() {
      this.showAdvancedSearchCondition = !this.showAdvancedSearchCondition
    },
    resetSearchForm() {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
