import request from '@/utils/request'

const baseUrl = '/api'

export function searchHomeTemplateDisplay (parameter) {
  return request({
    url: baseUrl + '/cms/homeTemplateDisplay/search',
    method: 'post',
    data: parameter
  }).then(res => {
    let data = res.data
    if (!data) {
      data = res.data
    }
    return {
      data: data.list,
      pageNo: data.pageInfo.pageNo ? data.pageInfo.pageNo : 1,
      totalCount: data.pageInfo.totalCount
    }
  })
}

export function getHomeTemplateDisplayData (parameter) {
  return request({
    url: baseUrl + '/cms/homeTemplateDisplay/get',
    method: 'post',
    data: parameter
  }).then(res => {
    let data = res.data
    if (!data) {
      data = res.data
    }
    return data
  })
}

export function onlineHomeTemplateDisplay (parameter) {
  return request({
    url: baseUrl + '/cms/homeTemplateDisplay/online',
    method: 'post',
    data: parameter
  }).then(res => {
    let data = res.data
    if (!data) {
      data = res.data
    }
    return data
  })
}
