<template>
  <page-header-wrapper>
    <div class="a-card">
      <HomeTemplateDisplaySearch
        :queryParam="queryParam"
      />
      <div class="table-operator">

        <a-button style="display:none" type="dashed" >
          {{ optionAlertShow && $t('button.close') || $t('button.open') }} alert
        </a-button>
        <a-dropdown v-if="selectedRowKeys.length > 0" v-action:edit>
          <a-menu slot="overlay">
            <a-menu-item key="1" class="hide" click="handleDeleteSelected">
              <a-icon type="delete"/>
              {{ $t('button.delete') }}
            </a-menu-item>
          <!-- lock | unlock -->
          </a-menu>
          <a-button style="margin-left: 8px">
            {{ $t('button.batchOperate') }}
            <a-icon type="down"/>
          </a-button>
        </a-dropdown>
      </div>
      <s-table
        :bordered="true"
        ref="table"
        :alert="options.alert"
        :data="loadData"
        :dataSource="data"
        :pagination="{ pageSize: 10,pageNo:1}"
        :rowSelection="options.rowSelection"
        :scroll="{ x: true}"
        :row-key="(record, index) => index">
        <a-table-column title="操作" align="center">
          <span slot-scope="record">
            <a v-if="record.onlineStatus!=='上线中'" @click="handleOnline(record)">{{ $t("button.online") }}</a>
          </span>
        </a-table-column>
        <!-- 首页模板发布版本号 -->
        <a-table-column :title="$t('columnName.homeTemplateDisplayNo')" align="left">
          <template slot-scope="record">{{record.homeTemplateDisplayNo}}</template>
        </a-table-column>
        <!-- 上线状态 -->
        <a-table-column :title="$t('columnName.onlineStatus')" align="left">
          <template slot-scope="record">{{getCode('onlineStatus', record.onlineStatus)}}</template>
        </a-table-column>
        <!-- 首页模板编号 -->
        <a-table-column :title="$t('columnName.homeTemplateNo')" align="left">
          <template slot-scope="record">{{record.homeTemplateNo}}</template>
        </a-table-column>
         <!-- 首页模板名称 -->
        <a-table-column :title="$t('columnName.homeTemplateName')" align="left">
          <template slot-scope="record">{{record.homeTemplateName}}</template>
        </a-table-column>
        <!-- 市场编码 -->
        <a-table-column :title="$t('columnName.marketplaceCode')" align="left">
          <template slot-scope="record">{{ record.marketplaceCode }} - {{ record.marketplaceName }}</template>
        </a-table-column>
        <!-- 语言 -->
        <a-table-column :title="$t('columnName.language')" align="left">
          <template slot-scope="record">{{getCode('languageName', record.language)}}</template>
        </a-table-column>
        <!-- 上线时间 -->
        <a-table-column :title="$t('columnName.onlineTime')" align="left">
          <template slot-scope="record">{{formatDateTime(record.onlineTime)}}</template>
        </a-table-column>
        <!-- 上线备注 -->
        <a-table-column :title="$t('columnName.onlineComment')" align="left">
          <div slot-scope="record" style="display: flex;flex-wrap:wrap " >
            <template v-for="(item, i) in record.onlineHistoryList" >
              <span :key="i">{{ item.comment }}</span> <br :key="i"/>
            </template>
          </div> 
        </a-table-column>
        <!-- 下线时间 -->
        <a-table-column :title="$t('columnName.offlineTime')" align="left">
          <template slot-scope="record">{{formatDateTime(record.offlineTime)}}</template>
        </a-table-column>
        <!-- 创建人 -->
        <a-table-column :title="$t('columnName.creatorName')" align="left">
          <template slot-scope="record">{{record.creatorName}}<br />{{formatDateTime(record.createTime)}}</template>
        </a-table-column>
        <!-- 更新人 -->
        <a-table-column :title="$t('columnName.updaterName')" align="left">
          <template slot-scope="record">{{record.updaterName}}<br />{{formatDateTime(record.updateTime)}}</template>
        </a-table-column>
      </s-table>
      <CommentEditor ref="commentEditor" @handleOk="handleCommentEditOk"/>
      <a-drawer :closable="false" :visible="showDetailDrawer" placement="right" width="70%" @close="onDetailDrawerClose">
        <HomeTemplateDisplayDetail ref="homeTemplateDisplayDetail" :options="detailOptions"></HomeTemplateDisplayDetail>
      </a-drawer>
    </div>
  </page-header-wrapper>
</template>

<script>
// 演示如何使用 this.$dialog 封装 modal 组件
import { onlineHomeTemplateDisplay, searchHomeTemplateDisplay } from '@/api/cms/homeTemplateDisplay'
import moment from 'moment'
import { STable } from '@/components'

import HomeTemplateDisplaySearch from '@/views/cms/HomeTemplateDisplaySearch'

import CommentEditor from '@/components/comment/CommentEditor'
import ApprovalEditor from '@/components/approval/ApprovalEditor'
import HomeTemplateDisplayDetail from '@/views/cms/HomeTemplateDisplayDetail'

export default {
  name: 'HomeTemplateList',
  components: {
    STable, CommentEditor, ApprovalEditor, HomeTemplateDisplaySearch, HomeTemplateDisplayDetail
  },
  data () {
    return {
      confirmModalLoading: false,
      // 查询参数
      queryParam: { },
      data: [],
      status: 'all',
      // 加载数据方法 必须为 Promise 对象
      // loadData: parameter => {
      //   console.log('loadData.parameter', parameter)
      //   return getHomeTemplate(Object.assign(parameter, this.queryParam))
      //       .then(res => {
      //         return res.result
      //       })
      // },
      selectedRowKeys: [],
      selectedRows: [],
      homeTemplateModalShow: false,
      homeTemplateData: {},
      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false,
      detailOptions: {},
      showDetailDrawer: false
    }
  },
  created () {
  },
  mounted () {

  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData (parameter) {
      return searchHomeTemplateDisplay(Object.assign(parameter, this.queryParam))
        .then(res => {
          console.log('loadData->searchHomeTemplate.parameter', parameter, res)
          return res
        })
    },
    handleDetail (record) {
      this.detailOptions = {
        homeTemplateDisplayId: record.homeTemplateDisplayId
      }
      this.showDetailDrawer = true
      this.$nextTick(() => {
        this.$refs.homeTemplateDisplayDetail.loadData(this.detailOptions)
      })
    },
    onDetailDrawerClose () {
      this.showDetailDrawer = false
    },
    handleOnline (record) {
      this.$refs['commentEditor'].show(record, 'online')
    },
    handleCommentEditOk (params) {
      const record = params.record
      const that = this
      record.onlineStatus = '上线中'
      if (params.type === 'online') {
        return onlineHomeTemplateDisplay(record)
          .then(res => {
            console.log('onlineHomeTemplateDisplay', record, res)
            that.$refs.table.refresh(true)
            that.$message.success('上线成功')
            return res
          }).catch(() => {
            that.$refs.table.refresh(true)
            that.$message.success('上线失败')
          })
      }
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    resetSearchForm () {
      this.queryParam = {
        date: moment(new Date())
      }
    }
  }
}
</script>
<style lang="less" scoped>
.ant-avatar-lg {
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.list-content-item {
  color: rgba(0, 0, 0, .45);
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  margin-left: 40px;

  span {
    line-height: 20px;
  }

  p {
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 22px;
  }
}
</style>
